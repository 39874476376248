<template>
  <div class="distance-navbar">
    <v-card>
      <v-img src="img/leaf-header.webp" class="card-image">
        <v-row no-gutters class="pt-4">
          <v-col cols="12">
            <v-card-title></v-card-title>
            <v-card-title></v-card-title>
            <v-toolbar-title class="config-title">
              {{ $t('configurations') }}</v-toolbar-title
            >
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            xl="11"
            lg="10"
            md="10"
            sm="10"
            xs="11"
            class="white--text user-title"
          >
            {{ $t('user.user') }}
          </v-col>

          <v-col
            xl="1"
            lg="2"
            md="2"
            sm="2"
            xs="1"
            :class="$vuetify.breakpoint.xs ? 'text-right mr-6' : ''"
          >
            <v-btn
              v-if="isAdmin"
              class="white--text text-none button-register-confirm"
              text
              @click="openFormRegisterUser"
            >
              <span v-if="!isMobile" class="mr-2">{{ $t('register_') }}</span>
              <v-icon class="icon-plus">mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-img>
      <modal-user ref="modalUser"> </modal-user>
    </v-card>
  </div>
</template>

<script>
import LayoutMixin from '@/components/LayoutMixin.vue'
import { logEvent, events } from '@/services/analytics'
import ModalUser from './ModalUser.vue'
import { mapGetters } from 'vuex'

export default {
  mixins: [LayoutMixin],

  components: {
    ModalUser,
  },

  computed: {
    ...mapGetters('user', ['isAdmin']),
  },

  methods: {
    openFormRegisterUser() {
      this.$refs.modalUser.openModal()
      logEvent(events.user.register)
    },
  },
}
</script>

<style scoped>
.card-image {
  height: 200px;
  border-radius: 8px;
}

.icon-plus {
  font-size: 1.2rem;
}
.button-register-confirm {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  background-color: #39af49 !important;
  border-color: #39af49;
}

.config-title {
  font-family: Source Sans Pro, sans-serif;
  color: #62cc70;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  padding-left: 40px;
}
.user-title {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 40px;
  padding-left: 40px;
}

@media (max-width: 425px) {
  .user-title {
    padding-right: 14px !important;
  }
}

@media (max-width: 600px) {
  .distance-navbar {
    margin-top: -30px;
  }
  .config-title {
    padding-left: 20px;
  }
  .user-title {
    padding-left: 20px;
    padding-right: 40px;
  }
}
</style>
