<template>
  <v-card class="card-style">
    <v-container>
      <df-loading v-show="loading"></df-loading>
      <div
        v-show="!loading"
        v-for="user in usersWithProfileUids"
        :key="user.email"
      >
        <v-row no-gutters class="mb-3" v-show="!loading">
          <v-col :cols="isMobile ? 6 : 4" class="text-start">
            <span class="item-name">{{ user.name }}</span>
            <div class="item-profile">
              {{ $t('profiles.' + user.profileUid) }}
            </div>
            <div v-if="isMobile">
              <v-icon class="icon-email">mdi-email</v-icon>
              <span class="item-email">{{ user.email }}</span>
            </div>
          </v-col>
          <v-col cols="4" v-if="!isMobile">
            <v-icon class="icon-email">mdi-email</v-icon>
            <span class="item-email">{{ user.email }}</span>
          </v-col>

          <v-col :cols="isMobile ? 6 : 4">
            <v-row no-gutters class="mb-3">
              <v-col
                :cols="isMobile ? 12 : 6"
                class="text-end d-flex justify-end"
              >
                <div class="d-inline-flex">
                  <span class="text-active d-flex">
                    {{ user.active ? $t('active') : $t('inactive') }}
                  </span>
                </div>
                <v-switch
                  v-if="isAdmin"
                  :disabled="!canDelete(user)"
                  class="d-inline-flex custom-switch"
                  v-model="user.active"
                  inset
                  hide-details
                  dense
                  @change="userActiveInactive(user.id)"
                ></v-switch>
              </v-col>
              <v-col :cols="isMobile ? 12 : 6" class="text-end">
                <v-btn
                  v-if="canEdit(user)"
                  class="buttons-actions py-4 elevation-0"
                  x-small
                  @click="editUser(user)"
                >
                  <v-icon class="icon-actions">mdi-pencil</v-icon></v-btn
                >
                <v-btn
                  v-if="canDelete(user)"
                  class="buttons-actions py-4 elevation-0 ml-2"
                  x-small
                  @click="openModalDeleteUser(user)"
                >
                  <v-icon class="icon-actions">mdi-delete</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <modal-user ref="modalUser"> </modal-user>
      <action-dialog
        :dialog="dialogConfirmation"
        :text="$t('user.delete_confirm')"
        :text-cancel="$t('cancelar')"
        :text-confirm="$t('confirmar')"
        :cancel="cancelDelete"
        :confirm="confirmDelete"
        color-cancel="#8f8f8f"
        color-confirm="#39af49"
      ></action-dialog>
    </v-container>
  </v-card>
</template>

<script>
import LayoutMixin from '@/components/LayoutMixin'
import { mapActions, mapGetters } from 'vuex'
import DfLoading from '@/lib/Loading'
import ModalUser from './ModalUser'
import ActionDialog from '@/components/Dialogs/ActionConfirmationDialog.vue'
import { logEvent, events } from '@/services/analytics'

export default {
  mixins: [LayoutMixin],

  data() {
    return {
      loading: false,
      profiles: [],
      dialogConfirmation: false,
    }
  },

  components: {
    ModalUser,
    DfLoading,
    ActionDialog,
  },

  created() {
    this.listProfile()
  },

  mounted() {
    this.fetchUsers()
  },

  computed: {
    ...mapGetters('user', ['users', 'currentUser', 'isAdmin']),

    usersWithProfileUids() {
      return this.users?.map((user) => ({
        ...user,
        profileUid: this.profiles?.find(
          (profile) => profile.id === user.profile_id
        )?.uid,
      }))
    },
  },

  methods: {
    ...mapActions('user', ['fetchUsers', 'deleteUser', 'changeUserStatus']),

    async listProfile() {
      this.loading = true

      const response = await this.$api.users.profiles.get()
      this.profiles = response.data

      this.loading = false
    },

    userLogged(userId) {
      return this.currentUser.id === userId
    },

    editUser(user) {
      this.$refs.modalUser.openModal(user)
      logEvent(events.user.edit)
    },

    canEdit(user) {
      return this.userLogged(user.id) || this.isAdmin
    },

    canDelete(user) {
      return !this.userLogged(user.id) && this.isAdmin
    },

    userActiveInactive(userId) {
      try {
        this.changeUserStatus(userId)
        logEvent(events.user.status)
      } catch (error) {
        console.error(error)
      }
    },

    openModalDeleteUser(user) {
      this.dialogConfirmation = true
      this.user = user
    },

    cancelDelete() {
      this.dialogConfirmation = false
    },

    confirmDelete() {
      try {
        this.deleteUser(this.user.id)
        logEvent(events.user.delete)

        this.$root.$emit('notify', 'success', this.$t('user.delete_success'))

        this.dialogConfirmation = false
      } catch (error) {
        this.dialogConfirmation = false
      }
    },
  },
}
</script>

<style scoped>
::v-deep .v-input--selection-controls {
  margin: -12px -11px 0px 0px;
}

::v-deep .v-input--switch__track {
  border-radius: 14px !important;
  height: 24px !important;
  background: #aab2a9 !important;
  opacity: 1 !important;
}

::v-deep .v-input--is-label-active > div > div > div > .v-input--switch__track {
  background: #4a76bc !important;
}

::v-deep .v-input--switch__thumb.primary--text {
  background: #fff !important;
  left: 1px !important;
  top: 2px !important;
}

::v-deep .v-input--switch--inset .v-input--selection-controls__input {
  width: 38px !important;
}

::v-deep .v-input--selection-controls__ripple {
  height: 0;
  width: 0;
}

::v-deep .v-input--switch__thumb {
  width: 17px !important;
  height: 19px !important;
}

.card-style {
  border-radius: 8px;
  margin: -40px 17px;
  padding: 20px;
}
.item-name {
  font-family: 'Rubik';
  font-weight: 500;
  color: #1a2b46;
}

.item-profile {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #aab2a9 !important;
}

.item-email {
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 14px;
  color: #5b6459;
}

.icon-email {
  padding-right: 10px;
  color: #aab2a9;
}

.buttons-actions {
  padding: 18px 10px;
  color: #aab2a9;
  margin-top: -2px;
}

.icon-actions {
  font-size: 15px !important;
  color: #788476 !important;
}

.text-active {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #788476;
  padding-right: 15px;
}

@media screen and (max-width: 425px) {
  .item-email {
    font-size: 10px;
  }
  .icon-email {
    font-size: 10px;
  }
  .item-profile {
    font-size: 10px;
  }
}
</style>
