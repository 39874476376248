<script>
import HeaderUser from '@/pages/users/Header.vue'
import ListUser from '@/pages/users/List.vue'

export default {
  components: {
    HeaderUser,
    ListUser,
  },
}
</script>

<template>
  <div>
    <header-user />
    <list-user />
  </div>
</template>
