<template>
  <v-dialog v-model="isOpen" width="780" persistent @keydown.esc="closeModal">
    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
    <v-card class="card-style">
      <v-toolbar flat>
        <v-toolbar-title class="title-header">
          {{ user.id ? $t('user.edit') : $t('user.register') }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon color="gray" @click="closeModal" right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="form-register">
        <v-form fast-fail ref="formRef">
          <v-row no-gutters class="title-description pt-5">
            <template>
              <v-col cols="12" md="6" class="text-start pl-1">
                {{ $t('nome') }}
              </v-col>
              <v-col
                cols="6"
                class="text-start pl-4 d-none d-sm-none d-md-flex"
              >
                {{ $t('email') }}
              </v-col>
            </template>
          </v-row>
          <v-row no-guttes class="mt-n2">
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-text-field
                v-model="user.name"
                background-color="white"
                :label="$t('nome')"
                :rules="rules.name"
                color="#39af49"
                outlined
                hide-details="auto"
                solo
                dense
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6">
              <v-text-field
                v-model="user.email"
                background-color="white"
                :rules="rules.email"
                color="#39af49"
                :label="$t('email')"
                outlined
                hide-details="auto"
                solo
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="title-description">
            <template>
              <v-col cols="12" md="6" class="text-start pl-1">
                {{ $t('profile') }}
              </v-col>
            </template>
          </v-row>
          <v-row no-guttes class="mt-n2">
            <v-col cols="12">
              <v-select
                v-model="user.profile_id"
                :items="setProfilesWithTranslate"
                item-value="id"
                :label="$t('profile')"
                return-object
                background-color="white"
                color="#39af49"
                outlined
                :rules="rules.profile_id"
                hide-details="auto"
                solo
                dense
                :disabled="!canEdit(user)"
                @change="changeProfile"
              >
                <template #item="data">
                  <div>
                    <div class="select-profile-title">{{ data.item.text }}</div>
                    <div class="select-profile-description">
                      {{ getDescription(data.item.id) }}
                    </div>
                  </div>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <div v-if="canEdit(user)">
            <v-divider
              color="grey darken-3"
              class="divider-point"
              dark
            ></v-divider>
            <v-row no-gutters class="selector-farm mt-5" v-if="!isAdminProfile">
              <template>
                <v-col cols="12" md="6" class="text-start pl-1">
                  {{ $t('farm.access_farm') }}
                </v-col>
              </template>
            </v-row>
            <v-row no-gutters v-if="!isAdminProfile">
              <v-col>
                <v-select
                  v-model="user.farms"
                  :items="farms"
                  item-text="name"
                  item-value="id"
                  return-object
                  background-color="white"
                  color="#39af49"
                  :rules="rules.farms"
                  dense
                  outlined
                  chips
                  solo
                  multiple
                  :disabled="!canEdit(user) && isAdminProfile(user.profile_id)"
                  :label="$t('fazenda.fazendas')"
                  @change="addFarms"
                >
                </v-select>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="distance-buttons">
        <v-btn class="text-none button-remove-and-close" @click="closeModal"
          ><span class="text-cancel-and-remove">{{ $t('cancelar') }}</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="white--text text-none button-register-confirm"
          @click="register"
          :loading="saving"
          :disabled="!formValid"
          >{{ user.id ? $t('editar') : $t('adicionar')
          }}<v-icon class="icon-button" small>mdi-plus-box</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { logEvent, events } from '@/services/analytics'

const adminProfilesUID = ['ADMIN', 'CONTA']

export default {
  data() {
    return {
      saving: false,
      user: {
        name: '',
        email: '',
        profile_id: null,
        active: true,
        farms: [],
      },
      profiles: [
        {
          id: null,
          name: null,
        },
      ],

      isOpen: false,
      rules: {
        name: [(value) => !!value || this.$t('required')],
        email: [
          (value) => !!value || this.$t('required'),
          (value) => {
            const regex =
              /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

            if (!regex.test(value)) {
              return this.$t('required_fields.email')
            }
            return true
          },
        ],
        profile_id: [(value) => !!value || this.$t('required')],
        farms: [
          (value) => !!value || this.$t('required'),
          (value) => {
            if (value.length === 0) {
              return this.$t('required_fields.at_least_one')
            }
            return true
          },
        ],
      },
      formValid: false,
    }
  },

  watch: {
    user: {
      handler() {
        this.validateForm()
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters('farms', ['farms']),
    ...mapGetters('user', ['currentUser', 'isAdmin']),

    setProfilesWithTranslate() {
      return this.profiles.map((profile) => {
        return {
          id: profile.id,
          text: this.$t('profiles.' + profile.uid),
        }
      })
    },

    isAdminProfile() {
      const profile = this.profiles?.find(
        (profile) => profile.id == this.user?.profile_id
      )

      if (profile && adminProfilesUID.includes(profile.uid)) {
        return true
      }

      return false
    },
  },

  methods: {
    ...mapActions('user', ['fetchUsers']),

    validateForm() {
      let validName = this.rules.name.every(
        (rule) => rule(this.user.name) === true
      )
      let validEmail = this.rules.email.every(
        (rule) => rule(this.user.email) === true
      )

      let validProfile = this.rules.profile_id.every(
        (rule) => rule(this.user.profile_id) === true
      )

      let validFarms = this.rules.farms.every(
        (rule) => this.isAdminProfile || rule(this.user.farms) === true
      )

      this.formValid = validName && validEmail && validProfile && validFarms
    },

    getDescription(profileId) {
      const profile = this.profiles?.find((profile) => profile.id == profileId)

      if (profile) {
        return this.$t('profiles.description.' + profile?.uid)
      }

      return ''
    },

    userLogged(userId) {
      return this.currentUser.id === userId
    },

    canEdit(user) {
      return !this.userLogged(user.id) && this.isAdmin
    },

    async listProfile() {
      const response = await this.$api.users.profiles.get()
      this.profiles = response.data
    },

    // accessed by refs
    // eslint-disable-next-line vue/no-unused-properties
    openModal(user = null) {
      this.listProfile()
      this.isOpen = true

      this.user = user ?? {
        name: null,
        email: '',
        profile_id: null,
        active: true,
        farms: [],
      }

      this.resetValidation(user)
    },

    resetValidation(user) {
      setTimeout(() => {
        user
          ? this.$refs.formRef.validate()
          : this.$refs.formRef.resetValidation()
      }, 100)
    },

    closeModal() {
      this.isOpen = false
    },

    async register() {
      try {
        this.saving = true
        const isEditing = this.user.id ? true : false

        this.getFarmsIds(this.user.farms)

        const action = isEditing
          ? this.$api.users.update
          : this.$api.users.create
        const response = await action(this.user)

        const user = response.data

        if (isEditing) {
          logEvent(events.user.update)
        } else {
          logEvent(events.user.create)
        }

        this.user = {
          id: user.id,
          name: user.name,
          email: user.email,
          profile_id: user.profile_id,
          active: user.active,
          farms: user.farms,
        }

        this.saving = false
        this.fetchUsers()

        this.$root.$emit('notify', 'success', this.$t('user.register_success'))

        this.closeModal()
      } catch (error) {
        this.saving = false

        throw error
      }
    },

    getFarmsIds(farms) {
      let farmsIds = null

      if (this.isAdminProfile) {
        farmsIds = this.farms.map((farm) => farm.id)
      } else {
        farmsIds = farms.map((farm) => {
          if (typeof farm === 'object') {
            return farm.id
          } else {
            return farm
          }
        })
      }

      this.user.farms = farmsIds
    },

    changeProfile(profile) {
      this.user.profile_id = profile.id
    },

    addFarms(farms) {
      this.user.farms = farms
    },
  },
}
</script>

<style scoped>
::v-deep .theme--light.v-select .v-select__selection--comma {
  color: #5b6459;
}
::v-deep .v-list-item__content {
  color: #5b6459;
}
::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: -10px;
}
::v-deep
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
}
::v-deep
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot {
  border-radius: 8px;
}

::v-deep .theme--light.v-input {
  color: #788476;
}

::v-deep .v-text-field.v-text-field--solo .v-input__control input {
  color: #5b6459;
}
::v-deep .title-description {
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #253521;
}
::v-deep.v-input--selection-controls .v-input__slot > .v-label {
  color: #0e180c;
}

.select-profile-title {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #253521;
}

.select-profile-description {
  color: #5b6459;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.card-style {
  border-radius: 8px;
}

.checkbox-lost-harvest {
  background: #e6e9e6;
  border-radius: 8px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 8px !important;
}

.divider-point {
  margin: 10px;
  margin-bottom: 15px;
}

.distance-buttons {
  padding: 10px 20px;
}

.title-header {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  color: #1a2b46;
}

.form-register {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 500px;
  padding: 21px 42px;
  background: #f5f5f5 !important;
}

.button-remove-and-close {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  background: rgba(0, 0, 0, 0.4) !important;
}

.button-register-confirm {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  background-color: #39af49 !important;
  border-color: #39af49;
}

.icon-button {
  padding-top: 2px;
  padding-left: 8px;
}

.text-cancel-and-remove {
  color: #f8f9f8;
}
.selector-farm {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #253521;
  padding-bottom: 10px;
}
</style>
